//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import refundDetail from "@/lib/data-service/haolv-default/consumer_air_ticket_refundDetail";
export default {
  name: "FlightRefundForm",
  data() {
    return {
      detail: null
    };
  },
  methods: {
    getDetail() {
      let orderNo = this.$route.query.orderNo;
      refundDetail({ orderNo }).then(res => {
        this.detail = res.orderrRfund;
      });
    }
  },
  created() {

  },
  activated() {
    this.getDetail();
  },
  watch: {},
  computed: {}
};
